import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/UI/Layout"
import Navbar from "../../components/UI/Navbar"
import Seo from "../../components/Seo"
import ServicePage from "../../components/templates/ServicePage"


const query = graphql`
  {
    allContentfulInstallationImages(
      filter: {
        metadata: {
          tags: { elemMatch: { contentful_id: { eq: "recreationPonds" } } }
        }
      }
    ) {
      nodes {
        id
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
          id
          title
        }
      }
    }
  }
`


export default function RecreationPondsPage() {
  const title = `Recreational Pond` // Singular (not plural)
  const {
    allContentfulInstallationImages: { nodes: image },
  } = useStaticQuery(query)

  const imageList = image[0].image


  return (
    <Layout>
      <Navbar />
      <Seo
        title={`${title}s`}
        description={`We build ${title}s and natural swimming ponds as an environmentally friendly outdoor recreation space.`}
      />

      <ServicePage
        recreationalPonds={true}
        title={title}
        imageList={imageList}
        img={
          <StaticImage
            src="../../assets/images/recreationalpond.jpg"
            quality={90}
            loading="eager"
            style={{
              position: "absolute",
              width: "100%",
              zIndex: 1,
              height: "21rem",
            }}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Recreational Pond Jacksonville Oregon"
          />
        }
        tagline={`A place for family and friends to gather`}
        body={[
          {
            id: 0,
            type: "paragraph",
            content: `The popularity of recreational ponds has been growing over the past few years. These eco-friendly swimming ponds have been built in Europe for decades and have recently gained traction here in the United States. You may have heard these types of ponds referred to as Natural Swimming Ponds or Natural Swimming Pools (NSP). Choosing a recreational pond over a traditional swimming pool may be just what your family is looking for.`,
          },
          {
            id: 1,
            type: "paragraph",
            content: `Using plants within either a planting zone or <a href="/new-installations/bogs-wetland-filters/" style="color: #da7235">wetland filter</a>, with a separate swimming zone, a recreational pond eliminates the need for harsh chemicals and is environmentally friendly. They fit seamlessly into your natural landscaping and provide a safe and enjoyable swimming experience for the whole family.`,
          },
          {
            id: 2,
            type: "paragraph",
            content: `You can have the feel of your favorite swimming hole in your own backyard, and it will definitely be the favorite hangout spot for your family and friends! A backyard swimming pond provides a fun outdoor recreational space during the warmer months and a natural environment for wildlife and your family to enjoy year-round.`,
          },
        ]}
      />
    </Layout>
  )
}
